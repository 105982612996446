<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan Website</h3>
  <div class="columns is-multiline text-left">
    <div class="column is-12">
      <div class="field">
        <label class="label">Nama Website</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.SITE_NAME.value"
            @change="changedValue('SITE_NAME')"
          />
        </div>
      </div>
    </div>

    <div class="column is-12" v-if="false">
      <div class="field">
        <label class="label">Link Logo Website</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.ICON.value"
            @change="changedValue('ICON')"
          />
        </div>
        <p class="help">Disarankan menggunakan cdn agar loading cepat</p>
      </div>
    </div>

    <div class="column is-12">
      <div class="field">
        <label class="label">Link Banner Game</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.GAME_BANNER.value"
            @change="changedValue('GAME_BANNER')"
          />
        </div>
        <p class="help">Disarankan menggunakan cdn agar loading cepat</p>
      </div>
    </div>

    <div class="column is-12" v-if="false">
      <div class="field">
        <label class="label">Link Gambar Background Website</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.SITE_BACKGROUND.value"
            @change="changedValue('SITE_BACKGROUND')"
          />
        </div>
        <p class="help">Disarankan menggunakan cdn agar loading cepat</p>
      </div>
    </div>

    <div class="column is-6">
      <div class="field">
        <label class="label">Link daftar</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.REGISTER_LINK.value"
            @change="changedValue('REGISTER_LINK')"
          />
        </div>
      </div>
    </div>

    <div class="column is-6">
      <div class="field">
        <label class="label">Link Login</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Text input"
            v-model="data.LOGIN_LINK.value"
            @change="changedValue('LOGIN_LINK')"
          />
        </div>
      </div>
    </div>

    <div class="column is-12">
      <div class="field">
        <label class="label">Menu Bottom Navigation (Mobile)</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="data.BOTTOM_NAVIGATION.value"
            @change="changedValue('BOTTOM_NAVIGATION')"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="column is-12">
      <div class="field">
        <label class="label">Konten Pra Footer (Sebelum Footer)</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="data.PRE_FOOTER.value"
            @change="changedValue('PRE_FOOTER')"
          ></textarea>
        </div>
        <p class="help">Preview dapat dilihat dibawah tombol save</p>
      </div>
    </div>

    <div class="column is-12">
      <button
        class="button is-primary is-fullwidth"
        @click="savePredefinedData"
      >
        Simpan perubahan
      </button>
    </div>
  </div>
  <b>Preview Konten</b>
  <div class="preview-konten mb-6">
    <div v-html="data.PRE_FOOTER.value"></div>
  </div>

  <h3 class="title is-3 mt-6">Pengaturan Konfigurasi Manual</h3>
  <div class="columns is-multiline text-left">
    <div class="column is-12">
      <div class="field">
        <label class="label">Key</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.key" />
        </div>
      </div>

      <div class="field">
        <label class="label">Value</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.value" />
        </div>
      </div>
    </div>
    <div class="column">
      <button class="button is-danger mr-1" @click="flushAllCache()">
        Bersihkan Semua Cache
      </button>
      <button
        class="button is-danger mr-1"
        @click="cancelEdit()"
        v-bind:disabled="!editButtonStatus"
      >
        Batal Edit
      </button>
      <button class="button is-success" @click="doSave()">Simpan Data</button>
    </div>
  </div>

  <div class="mt-6">
    <div class="table-container">
      <table
        class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left"
      >
        <thead>
          <tr>
            <th>Aksi</th>
            <th>Key</th>
            <th>Value</th>
            <th>Jenis</th>
            <th>Cached</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Aksi</th>
            <th>Key</th>
            <th>Value</th>
            <th>Jenis</th>
            <th>Cached</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="(data, index) in tableData" :key="index">
            <td class="has-text-centered">
              <button class="button is-success mr-1 mb-1" @click="doEdit(data)">
                Edit
              </button>
              <button
                class="button is-danger mr-1 mb-1"
                @click="doDelete(data)"
              >
                Hapus
              </button>
              <button
                class="button is-danger"
                @click="clearSingleCache(data)"
                v-bind:disabled="!data.cached"
              >
                Flush Cache
              </button>
            </td>
            <td>{{data.key}}</td>
            <td>{{data.value}}</td>
            <td>{{data.type}}</td>
            <td>{{data.cached}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
