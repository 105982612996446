import { ref, onMounted } from "vue";

import { handleError } from "@/utils/errorHandler";
import Config_Service from "@/services/config_services";

import AdminNavMenu from "@/components/AdminNavMenu";

export default {
  name: "Home",
  components: { AdminNavMenu },
  setup(props, context) {
    const inputData = ref({
      newEntry: true,
      key: undefined,
      value: undefined,
    });
    const editButtonStatus = ref(false);

    const data = ref({
      SITE_NAME: { changed: false, value: undefined },
      ICON: { changed: false, value: undefined },
      GAME_BANNER: { changed: false, value: undefined },
      SITE_BACKGROUND: { changed: false, value: undefined },
      REGISTER_LINK: { changed: false, value: undefined },
      LOGIN_LINK: { changed: false, value: undefined },
      BOTTOM_NAVIGATION: { changed: false, value: undefined },
      PRE_FOOTER: { changed: false, value: undefined },
    });

    const tableData = ref([
      {
        key: undefined,
        value: undefined,
        lastUpdateBy: undefined,
        type: undefined,
        cached: false,
      },
    ]);

    const fetchData = async () => {
      try {
        Object.keys(data.value).forEach(async (key) => {
          let fetchedValue = await Config_Service.getOne(key);
          data.value[key].value = fetchedValue.data.value;
        });

        let response = await Config_Service.getAll();
        tableData.value = response.data.data;
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const savePredefinedData = () => {
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        try {
          Object.keys(data.value).forEach(async (key) => {
            if (data.value[key].changed) {
              await Config_Service.updateData(key, {
                value: data.value[key].value,
              });
              data.value[key].changed = false;
            }
          });
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const changedValue = (key) => {
      data.value[key].changed = true;
    };

    const doDelete = async (passedData) => {
      let confirmation = confirm(
        `Yakin menghapus data ${passedData.key}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await Config_Service.deleteData(passedData.key);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const clearSingleCache = async (passedData) => {
      let confirmation = confirm(
        `Yakin menghapus cache ${passedData.key}? Performa server mungkin akan terpengaruh`
      );
      if (confirmation) {
        try {
          let response = await Config_Service.clearSingleCache(passedData.key);
          if (response.data.success) {
            fetchData();
            alert(`Cache ${passedData.key} berhasil dihapus`);
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const flushAllCache = async () => {
      let confirmation = confirm(
        `Yakin menghapus semua cache? Performa server mungkin akan terpengaruh`
      );
      if (confirmation) {
        try {
          let response = await Config_Service.FlushAllCache();
          if (response.data.success) {
            alert("Cache Berhasil dibersihkan");
            fetchData();
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doEdit = (data) => {
      inputData.value = { ...data };
      inputData.value.newEntry = false;
      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value.key = undefined;
      inputData.value.value = undefined;
      inputData.value.newEntry = true;
      editButtonStatus.value = false;
    };

    const doSave = () => {
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        if (!inputData.value.newEntry) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        let response = await Config_Service.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await Config_Service.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      data,
      editButtonStatus,
      clearSingleCache,
      flushAllCache,
      savePredefinedData,
      changedValue,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
